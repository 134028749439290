.header {
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  margin-top: -20px;
  margin-right: -20px;
  position: relative;
  .headerBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .headerImg {
    height: 105px;
    position: absolute;
  }
}

