.container {
  .addEvent {
    background-color: black;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 10px;
    .icon {
      margin-left: 5px;
    }
  }
  .switch {
    margin: 25px 10px;
    justify-content: flex-start;
  }
  .title {
    margin: 0px;
    margin-bottom: 25px;
  }
  .eventsContainer {
    margin-top: 25px;
  }
  .eventsByMonthContainer {
    font-size: 14px;
    :global(.mobile) & {
      font-size: 12px;
    }

    margin: 1em 0px;
    .monthName {
      font-weight: bold;
      font-size: 1em;
    }
    .eventContainer {
      padding: 0.33em 1em;
      display: flex;
      .actionContainer {
        margin-left: auto;
        display: flex;
        > :first-child.actionButton {
          margin-left: 0px;
        }
        .actionButton {
          cursor: pointer;
          margin-left: 10px;
        }
      }
      :global(.switch) {
        justify-content: normal;
        align-items: normal;
        label {
          margin-left: 5px;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
}
