.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  
  --button-border-color: rgba(168, 172, 184, 0.34);

  .button {
    box-shadow: 0px 6.80597px 23.8209px rgba(168, 172, 184, 0.18);
    position: relative;
    font-size: 12px;
    border: 1px solid var(--button-border-color);
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
    padding: 15px 41px;

    margin: 0 5px;
    &:last-child {
      margin-right: 0px;
    }
    &:first-child {
      margin-left: 0px;
    }
    
    &.selected {
      background: #f1f5f5;
      svg {
        position: absolute;
        top: 10%;
        right: 5%;
        width: 16px;
        height: 16px;
        color: #00CEDD;
      }
      // color: white;
    }
    &:not(.selected):hover {
      box-shadow: 0 0 13px #cac9c9;
    }
  }
}

// .mobile {
//   .new-multi-option-button{
//     height: 33px;
//   }
// }
