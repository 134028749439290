.header {
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  margin-top: -20px;
  margin-right: -20px;
  position: relative;
  .headerBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .headerImg {
    height: 105px;
    position: absolute;
  }
}
:global(.react-modal) {
  .bodyContainer {
    :global(.hide-used-photos) {
      label {
        font-size: 11px;
      }
      :global(.react-toggle-track-x) {
        margin-top: auto;
        margin-bottom: auto;
        width: 5px;
        height: 9px;
        svg {
          width: 9px;
          height: 9px;
        }
      }
      :global(.react-toggle-track-check) {
        margin-top: auto;
        margin-bottom: auto;
      }
      :global(.react-toggle-thumb) {
        width: 15px;
        height: 15px;
      }
      :global(.react-toggle-track) {
        width: 35px;
        height: 17px;
      }
      :global(.react-toggle--checked) {
        :global(.react-toggle-thumb) {
          left: 19px;
        }
      }
      :global(.react-toggle-track-check) {
        left: 5px;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  :global(.action-buttons-vertical) {
    :global(.button) {
      max-width: 300px;
      min-width: 237px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
