$color_1: var(--background-grey);
$border-color_1: var(--delimiter-color);

.verticalSelectContainer {
  margin: 0px 10px 0px 10px;
}

.verticalSelectOption {
  border-top: 1px solid;
  border-color: $border-color_1;
  padding: 15px 0px 15px 0px;
  cursor: pointer;
  &:hover {
    color: var(--accent-light-color);
  }
  &:active {
    color: var(--accent-light-color-darken);
  }
  * {
    cursor: pointer;
  }
  small {
    color: $color_1;
    white-space: pre-wrap;
  }
  img {
    height: 80px;
    width: 80px;
    margin-left: -10px;
    margin-right: 10px;
  }
  &:last-child {
    border-bottom: 1px solid;
    border-color: $border-color_1;
  }
}

.onlyInnerDelimiters {
  .verticalSelectOption {
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      border-top: none;
    }
  }
}
.verticalSelectOptionContent {
  margin: 0px 10px 0px 10px;
  position: relative;
  display: flex;
  .small.preservePaddingForCheck {
    margin-right: calc(var(--check-circle-size) + var(--check-right));
  }
  &.--check-right {
    --check-right: -10px;
  }
  &.checked {
    &::before {
      bottom: 0px;
    }
    &::after {
      bottom: 0px;
    }
    .small {
      margin-right: calc(var(--check-circle-size) + var(--check-right));
    }
  }
  &.emptyDescription {
    &::before {
      bottom: -8px;
    }
    &::after {
      bottom: -8px;
    }
  }
}
