.nav-bar-button.button {
  display: flex;
  font-size: 18px;
  height: 2.22em;
  // margin-top: 7px;
  padding: 0.38em 1.22em;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  // * {
  //   margin-left: 16px;
  // }
  :first-child {
    margin-left: 0px;
  }
  svg + span {
    margin-left: 0.55em;
  }
  span + svg {
    margin-left: 0.55em;
  }
  svg {
    width: 1em;
    height: 1em;
  }
}
.mobile {
  .navbar-right {
    margin-left: unset;
  }
  .navbar-center {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-bar-button.button {
    font-size: 14px;
  }
}
