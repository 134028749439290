$action-container-height: 45px;
$action-container-height-mobile: 86px;
$action-container-top: 15px;
$action-buttons-height: 31px;
// $avoidOrderButtonPadding: 140px;
$select-font-size: 14px;

@mixin actionBarSelect($minWidth, $maxWidth: 100px) {
  &__control {
    font-size: $select-font-size;
    min-height: 33px;
    max-height: 33px;
    max-width: $maxWidth;
    min-width: $minWidth;
  }
  &__indicator {
    padding: 0px !important;
  }
  &__indicator-separator {
    display: none;
  }
}

.action-container {
  display: flex;
  user-select: none;
  transition-duration: 0.3s;
  padding-top: 15px;
  padding-left: calc(var(--toolbar-width));
  position: fixed;
  z-index: var(--z-action-bar);
  width: 100%;

  :not(.content) &.toolbar-opened {
    padding-left: calc(var(--toolbar-view-width) + var(--toolbar-width));
  }
  .mobile :not(.content) &.toolbar-opened {
    padding-left: 0px;
  }
  .mobile & {
    padding: 0;
  }

  .action {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px 0;
    width: auto;
    // width: min-content;

    > *:not(:first-child) {
      margin-left: 12px;
    }
    .number-input {
      @include actionBarSelect(65px);
    }

    .day-format-select {
      @include actionBarSelect(65px);
    }

    .font-family-input {
      @include actionBarSelect(120px);
    }

    .order-shapes {
      @include actionBarSelect(55px);
      &__value-container {
        padding-right: 0px;
      }
      &__menu {
        width: max-content;
        padding: 5px;
      }
    }

    .effects {
      @include actionBarSelect(70px, 180px);
      &__control {
        border: none;
        font-size: 16px;
        box-shadow: none;
        min-height: 31px;
        max-height: 31px;
      }
      &__value-container {
        padding-right: 0px;
      }
      &__menu {
        width: 200px;
        padding: 5px;
        .icon-button {
          margin-left: 5px !important;
          margin-right: 5px !important;
        }
        // padding-right: 20px;
      }
      &__value-container--has-value {
        padding: 0px;
      }
    }

    .icon-button-container {
      height: $action-buttons-height;
      display: flex;
      line-height: $action-buttons-height;
      position: relative;
      cursor: pointer;

      svg {
        fill: black;
        // stroke: black;
      }

      &:hover {
        color: var(--accent-light-color);
        svg {
          fill: var(--accent-light-color);
          stroke: var(--accent-light-color);
        }
      }

      > * {
        margin: 0;
        margin-right: 4px;
      }
      p {
        margin-left: 4px;
      }
      :last-child {
        margin-right: 0px;
      }
      :not(:first-child) {
        margin-left: 4px;
      }
      .icon-button {
        margin-top: calc((#{$action-buttons-height} - 19px) / 2);
        margin-left: auto;
        margin-right: auto;
        stroke: #343434;
        stroke-width: 5;
        width: 19px;
        height: 19px;
      }
    }
  }
}
.sub-action-container {
  position: fixed;
  top: calc(#{$action-container-top} * 2 + #{$action-container-height});
  z-index: var(--z-color-picker);
  left: 0px;
  width: 100%;
  pointer-events: none;
  &.hide {
    display: none;
  }
  &.effects {
    .action {
      max-width: 300px;
      width: max-content;
    }
  }
}

.action {
  background: white;
  pointer-events: all;
  border-radius: 10px;
  display: flex;
  padding: 7px 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 970px;
  box-shadow: 0 0 13px #f1f1f1;
}

.mobile {
  .action-container {
    top: unset;
    bottom: $action-container-top;
    .action {
      flex-wrap: wrap !important;
      width: 95%;
      .break + * {
        margin-left: 0px;
      }
      .break {
        height: 10px;
      }
      > * {
        flex: 1 1;
        text-align: center;
      }
    }
  }
  .sub-action-container {
    top: unset;
    bottom: calc(#{$action-container-top} * 2 + #{$action-container-height-mobile});
    .action {
      width: 95%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
