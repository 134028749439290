$action-buttons-height: 31px;

.collapse {
  display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 10px 0;
  gap: 10px 0;
  width: auto;
  border-radius: 5px;
  padding: 0 5px;
  box-shadow: 0 0 10px #d5d5d5;
  > *:not(:first-child) {
    margin-left: 12px;
  }
}

.iconArrow {
  margin-top: calc((#{$action-buttons-height} - 18px) / 2);
  stroke: black;
  stroke-width: 4;
  width: 19px;
  height: 19px;
}

.collapseTitleContainer {
  display: flex;
  gap: 10px;
  .collapseTitle {
    margin-top: auto;
    margin-bottom: auto;
  }
}
