:global(._loading_overlay_wrapper).loading {
  display: flex;
}
:global(.mobile) :global(._loading_overlay_wrapper) {
  display: block;
}
:global(._loading_overlay_wrapper).loading > *:not(:global(._loading_overlay_overlay)) {
  display: none;
}
:global(._loading_overlay_wrapper) > :global(._loading_overlay_overlay) {
  background: white;
}
:global(.mobile) .loading-wrapper:global(._loading_overlay_wrapper) > :global(._loading_overlay_overlay) {
  top: var(--navbar-height);
  height: calc(100% - var(--navbar-height));
}
:global(._loading_overlay_wrapper) > :global(._loading_overlay_overlay) :global(._loading_overlay_content) {
  filter: invert(1);
}

.loadingWrapper {
  width: 100%;
  height: 100%;
}
:global(.mobile) {
  .loadingWrapper {
    position: fixed;
  }
}

:global(.mobile) {
  :global(.photoLibrary) {
    position: relative !important;
  }
}
