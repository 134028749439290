.checkbox {
  font-size: 20px;
  :global(.rc-checkbox-inner) {
    width: 1em;
    height: 1em;
    background-color: #fff;
    border: 1px solid #AAAAAA;
    border-radius: 0.25em;
  }
  :global(.rc-checkbox-input):focus + :global(.rc-checkbox-inner) {
    border-color: #AAAAAA;
  }
  &:global(.rc-checkbox-checked) :global(.rc-checkbox-inner) {
    &:after {
      border-color: var(--accent-light-color);
      width: 0.2em;
      height: 0.4em;
      left: 0.3em;
      top: 0.15em;
      border-width: 0.07em;
    }
  }
}
.label {
  cursor: pointer;
  transition-duration: 300ms;
  margin-left: 6px;
  &:active {
    opacity: 0.5;
  }
}