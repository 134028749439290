.container {
  // background-color: white;
  // box-shadow: 0px 6.80597px 23.8209px rgba(168, 172, 184, 0.18);

  .dayIcon {
    pointer-events: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: calc(50% - 40px/2);
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border: 1.3px solid black;
    border-radius: 8px;
    text-align: center;
    p {
      line-height: 1;
      margin: 0px;
      font-weight: bold;
      display: inline-block;
    }
    :global(.selected) & {
      color: white;
      background-color: black
    }
    // .icon-day-text {
    //   font-weight: bold;
    // }
  }
}