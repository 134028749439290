.toolbar.edit {
  .toolbar-view {
    padding: 0;
    > div {
      margin-top: auto;
      margin-bottom: auto;
      margin: 10px;
      &:first-child {
        margin-left: auto;
      }
      &:first-child:hover {
        cursor: pointer;
        color: var(--accent-light-color);
      }
      &:last-child {
        margin-right: auto;
      }
    }
    // top: var(--navbar-height);
    position: fixed;
    height: 44px;
    z-index: 2;
    box-shadow: inset 0 0 0 1px #c6c6c6;
    width: 100%;
    left: 0px;
  }
}
.mobile .toolbar.edit {
  .toolbar-view {
    > div {
      margin: auto;
      //fix in narrow screen, center of two columns is shifted
      flex: 1;
      text-align: center;
    }
    top: unset;
    height: var(--footer-height);
    bottom: 0;
    z-index: 11;
    position: fixed;
  }
}
