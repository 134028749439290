.gridItem {
  // padding-left: 10px;
  // padding-top: calc(130px - 10px) !important;

  // :global(.mobile) & {
  //   padding-top: 25% !important;
  //   min-width: 115px;
  //   min-height: 50px;
  // }
}
.button {
  width: 100%;
  height: 100%;
  padding: 0px !important;
  display: flex;
  border: none !important;
  // border-color: var(--toolbar-bg);
  float: right;
  > * {
    margin: auto;
    &:first-child {
      margin-right: 0 !important;
    }
    &:last-child {
      margin-left: 5px !important;
    }
  }
}
