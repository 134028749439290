.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.orientationButton {
	margin-top: 10px;
	margin-bottom: 35px;
	:global(.mobile) & {
		margin-bottom: 0px;
	}
}
