.container {
  display: flex;
  & > * {
    flex: 1 1 auto;
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
