.container {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}
.scene {
  width: 100%;
  height: 100%;
  transition-duration: 0.3s;
  transform: translateX(0);
}
.sceneToolbarOpened {
  transform: translateX(calc(var(--toolbar-view-width) / 2));
  :global(.mobile) & {
    transform: translateX(0);
  }
}
