.container {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  .inner {
    max-width: 900px;
    margin: 0 auto;
  }
  .pageDescription {
    text-align: center;
    font-size: 15px;
  }
  .pages {
    .pageContainer {
      width: 45%;
      height: min-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
    }
    display: flex;
    flex-wrap: wrap;
    min-height: min-content;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: 20px;
    .editIcon {
      height: 1em;
      width: 1em;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    .inner {
      max-width: calc(95% - var(--toolbar-view-width));
    }
    .pages {
      .pageContainer {
        width: 30%;
      }
    }
  }
}

.photoActionsContainer {
  .photoActions {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  display: flex;
  margin-right: auto;
  margin-left: auto;
  color: #8a8d90;
  padding: 10px 0px;
  width: 100%;
  justify-content: space-evenly;
}

:global(.mobile) {
  .container {
    padding: 10px 10px;
    .pages {
      margin-bottom: 5.38em;
      .pageContainer {
        width: 100%;
      }
      > :not(:first-child) {
        margin-top: 50px;
      }
      //   > * {
      //     margin: 2px;
      //     margin-left: auto;
      //     margin-right: auto;
      //   }
    }
  }
}

.photoBookContainer {
  display: flex;
  margin-bottom: 28px;
}
