$border-radius: 1em;
$toolbar-height: 5.38em;
$h-padding: 1em;
$v-padding: 1em;
$panel-margin: 10px;
$panel-bg: #fff;
$toolbar-bg: $panel-bg;

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  // padding: 0 $h-padding $v-padding $h-padding;
  padding: $v-padding $h-padding;
  font-size: 10px;
  width: 100%;
}

.toolbar {
  width: 100%;
  background-color: $toolbar-bg;
  // overflow: hidden;
  border-radius: $border-radius;
  height: $toolbar-height;
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    height: 100%;
    li {
      height: 100%;
      max-width: 100%;
      list-style: none;
      flex: 1 1;
      border-radius: $border-radius;
      transition-duration: 0.3s;

      &.active {
        $active-button-top-shift: 7px;
        $active-button-extra-height: calc(#{$border-radius} + #{$panel-margin} - #{$active-button-top-shift});
        position: relative;
        z-index: var(--z-toolbar-active-item);
        background-color: $panel-bg;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: calc(#{$active-button-extra-height} * -1 - #{$active-button-top-shift});
        height: calc(100% + #{$active-button-extra-height});
        -webkit-tap-highlight-color: transparent;
      }

      button {
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: #555;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        // border-top-left-radius: 15px;
        // border-bottom-left-radius: 15px;
        margin: 0;
        border: none;
        transition: 0.15s;
        user-select: none;
        border-radius: $border-radius;
        &:active {
          background-color: rgba(0, 0, 0, 0.05);
        }
        .buttonIcon {
          width: 1.84em;
          height: 1.84em;
          margin-bottom: 0.27em;
        }
      }
    }
  }
}

.panel {
  width: 100%;
  position: relative;
  z-index: var(--z-toolbar-active-panel);

  h2 {
    margin-top: 0;
  }
  .panelContent {
    padding: $border-radius 1em;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    left: 0;
    bottom: calc(100% + #{$panel-margin});
    height: auto;
    width: 100%;
    background-color: $panel-bg;
    box-sizing: border-box;
    box-shadow: unset;
    max-height: calc(var(--vh, 1vh) * 70 - var(--navbar-height));
    border-radius: 15px;
  }
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(58, 58, 58, 0.56);
  z-index: var(--z-toolbar-backdrop);
}
