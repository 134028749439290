.container {
  .goBackButton {
    border: none;
    padding-left: 0px;
  }
}
.backgroundSelectOption {
  border: none !important;
  transition-duration: 0.3s;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: white !important;
    border: none !important;
  }
  &:hover,
  &:active {
    transform: scale(1.1);
  }
}

.photoOption {
  border: 1px solid #ececec;
  border-radius: 5px;
  :global(.mobile) & {
    margin-right: 5px;
  }

  img {
    object-fit: contain;
  }
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
