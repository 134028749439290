.multi-option-button-container {
  display: flex;
  width: 100%;
  padding: 0 10% 0 10%;
  --button-border-color: var(--black-color);
  --selected-button-color: var(--black-color);
}

.multi-option-button {
  height: 45px;
  border: 1px solid;
  border-color: var(--button-border-color);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  border-right: 0;
  cursor: pointer;
  color: var(--selected-button-color);
  transition: 0.25s;
  &.left {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }
  &.right {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    border-right: 1px solid;
    border-color: var(--button-border-color);
  }
  &.selected {
    background: var(--selected-button-color);
    color: white;
  }
  &:not(.selected):hover {
    background: #e5e7e7;
  }
}
.mobile .multi-option-button {
  height: 33px;
}
