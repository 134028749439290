$margin: 5px;

.container {
  display: block;
  position: relative;
  // margin-top: -$margin;
  // margin-bottom: -$margin;
  margin: -$margin;
  padding-top: $margin;
  padding-bottom: $margin;
  :global(.mobile) & {
    overflow: hidden;
  }
}

.grid {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: $margin;
  padding-bottom: $margin;
  flex-wrap: wrap;
  :global(.mobile) &:not(.gridWrapMobile) {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.gridItem {
  flex-grow: 0;
  flex-shrink: 0;
  padding: $margin;

  // :global(.mobile) & {
  //   &:first-child {
  //     padding-left: 0;
  //   }
  //   &:last-child {
  //     padding-right: 0;
  //   }
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
}

:global(.configuration_photoBook) {
  .gridItem {
    width: 100px;
    height: 100px;
  }
}

.gridItemInner {
  height: 100%;
  position: relative;
}

.scrollIndicatorContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;
}

.scrollStartIndicator,
.scrollEndIndicator {
  background: linear-gradient(90deg, white 17%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  font-size: 20px;
  width: 40px;
  height: 100%;
  z-index: 10;
  left: 0;
  align-items: center;
  display: flex;
}

.scrollEndIndicator {
  transform: rotate(180deg);
  right: -0;
  left: auto;
}
