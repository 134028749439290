.delete-design {
  margin-left: auto;
  margin-top: 5px;
  width: min-content;
  position: absolute;
  background: white;
  font-size: 11px;
  border-radius: 10px;
  right: 5px;
  color: grey;
}
.design-title {
  font-size: 1em;
  margin-top: 10px;
}
.design-description {
  font-size: 0.75em;
  color: grey;
  width: 90%;
  margin-top: 5px;
  overflow: hidden;
}