.number-input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
  width: 40px;
}

.edit-contrast {
  width: 100%;
  display: table;
  .slider-container {
    width: 100%;
  }
  .value-slider {
    text-align: center;
    background-color: #fff;
    // border: 1px solid grey;
    border-radius: 6px;
    font-size: 90%;
    padding: 2px 3px;
    width: 40px;
  }
  > * {
    display: table-row;
    > * {
      display: table-cell;
      padding: 5px;
    }
  }
}
