.gridSelectOption {
  border: 1px solid #a7a7a7;
  border-radius: 15px;
  padding: 8px 7px;
  height: 100%;
  &:hover {
    border: 1px solid var(--accent-light-color);
    color: white;
    background-color: var(--accent-light-color);
  }
  &:active {
    border: 1px solid var(--accent-light-color-darken);
    background-color: var(--accent-light-color-darken);
  }
  cursor: pointer;
  h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 14px;
  }
  small {
    font-weight: bold;
    font-size: 12px;
    opacity: 0.5;
    white-space: pre-wrap;
  }
}
