@mixin switchBtn($switch-button-size, $switch-button-svg-size, $switch-border-width) {
  width: $switch-button-size;
  height: $switch-button-size;
  // padding: calc((#{$switch-button-size} - #{$switch-border-width} - #{$switch-button-svg-size}) / 2);
  border: $switch-border-width solid #2f2f2f;
  color: #000;
  border-radius: 5px;
  display: flex;
  background-color: var(--bg);
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.3s;
  svg {
    width: $switch-button-svg-size;
    // height: auto;
    height: $switch-button-svg-size;
  }
  &:disabled {
    cursor: default;
    background-color: transparent !important;
    border-color: #aaa !important;
    svg {
      color: #aaa;
    }
  }
  &:hover:not(:disabled) {
    // background-color: var(--accent-light-color);
    background-color: #fff;
  }
  &:active:not(:disabled) {
    background-color: rgba(0, 206, 221, 0.5);
  }
}

.container {
  position: absolute;
  top: 50%;
  display: flex;
  width: 100%;

  :global(.mobile) & {
    position: absolute;
    top: -70px;
    display: flex;
    width: 100%;
  }

  .pageSwitchBtnContainer {
    position: absolute;
    display: flex;
    flex-direction: column;

    .btnMonthLabel {
      width: 100%;
      margin-top: 5px;
      color: #939393;
      font-size: 10px;
      text-align: center;
      position: absolute;
      top: 100%;
      :global(.mobile) & {
        display: none;
      }
    }
    &.prev {
      left: -100px;
      // left: 0;
      :global(.mobile) & {
        margin-left: auto;
        position: initial;
      }
      svg {
        transform: rotate(180deg);
      }
    }
    &.next {
      right: -100px;
      // right: 0px;
      text-align: right;
      align-items: flex-end;

      :global(.mobile) & {
        margin-right: auto;
        position: initial;
      }
    }
  }

  .btn {
    @include switchBtn(50px, 25px, 1px);
    :global(.mobile) & {
      @include switchBtn(24px, 15px, 1px);
    }
  }

  .pageLabel {
    display: none;
    :global(.mobile) & {
      font-size: 18px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
      width: 145px;
      display: initial;
    }
  }
}

.smallLabel {
  display: inline;
  font-size: 11px;
  color: #939393;
  position: absolute;
  top: -18px;
  left: 5px;
  :global(.mobile) & {
    font-size: 10px;
    top: -15px;
  }
}
