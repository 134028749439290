// :import("../NewGrid/Grid.module.scss") {
//   imported-grid: grid;
// }

.photo-library {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: white;
  font-size: 0.8em;

  .no-photos {
    font-size: 1em;
    color: gray;
    margin-top: 1em;
    text-align: center;

    span {
      font-size: 0.9em;
      margin-left: 10px;
    }
  }

  .buttons-photos {
    display: flex;
    flex-direction: column;
  }

  .hide-used-photos {
    margin-top: 10px;
    label {
      font-size: 14px;
      font-weight: normal;
      margin-left: 7px;
    }
    margin-bottom: 10px;
  }

  &.compact {
    flex-direction: column;
    width: 100%;
    .buttons-container {
      margin: 0 auto 15px auto;
    }
  }
  &:not(.compact) .buttons-container {
    flex-shrink: 0;
    flex-basis: 20%;
    min-width: 200px;
    max-width: 500px;
    border-right: 1px solid var(--toolbar-bg);
    margin-right: 40px;
    padding-right: 10px;
    height: 200px;
  }
  .folder {
    flex-direction: column;
    cursor: pointer;

    > svg {
      height: 75px;
      width: 100% !important;
      margin: 0 auto;
      max-height: 100%;
    }
    > span {
      margin: -5px 0;
      text-align: center;
      font-size: 13px;
    }
  }
  .progress-bar-container {
    position: absolute;
    width: 100%;
    // padding: 5px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    height: 25px;
  }
  .progress-text {
    // background-color: white;
    text-align: center;
  }

  .LinesEllipsis {
    white-space: pre-wrap;
    //Instead of the line below you could use @include word-break($value)
    word-break: break-all;
    text-align: center;
  }
}

.mobile {
  .photo-library {
    height: 100%;
    flex-direction: column;
    &:not(.compact) .buttons-container {
      flex-basis: 0;
      border-right: none;
    }

    .no-photos {
      font-size: 1.2em;
    }

    .buttons-photos {
      flex-direction: column;
      &.flex-column {
        flex-direction: column;
      }
      .buttons-container {
        flex: 3;
        .button-upload-photo {
          padding: 11px 25px;
          width: 100%;
        }
      }
      .hide-used-photos {
        flex: 2;
      }
    }

    .grid {
      max-height: 175px;
      // flex-direction: row;
      // justify-content: flex-start;
      // flex-wrap: nowrap;
      .grid-item {
        &.folder {
          width: 19% !important;
        }
      }
    }
    .buttons-container {
      // margin-left: 5px;
      // margin-top: calc(var(--footer-title-height) * -0.8);
      margin-bottom: 5px;
      button {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale(0.9);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center;
        // transform-origin: top left;
      }
    }
  }
}

.configuration_photoBook {
  .photo-library {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    &:not(.compact) .buttons-container {
      flex-basis: 0;
      border-right: none;
    }

    .no-photos {
      font-size: 1.2em;
    }
    .buttons-container {
      z-index: 10;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
    }
    .global-grid {
      flex-wrap: wrap;
      flex-direction: column;
      height: 210px;
      overflow-x: auto;
      overflow-y: hidden;
      align-content: start;
      .grid-item {
        height: 100px;
        width: 100px;
      }
    }
  }
}

.go-back {
  margin-right: auto;
}
.toolbar-view .go-back {
  margin-left: auto;
}
