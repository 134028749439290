$width: 225px;
$buttonWidth: 20px;
.container {
  position: relative;
}
.colorPickerContainer {
  position: absolute;
  z-index: var(--z-color-picker);
  // margin-top: 40px;
  width: $width;
  top: 100%;
  left: -$width / 2 + $buttonWidth / 2;
  :global(.mobile) & {
    position: fixed;
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  :global(.chrome-picker) {
    :global(.mobile) & {
      width: 100% !important;
    }
  }
}
