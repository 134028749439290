.containerNewEditor {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  transition-duration: 0.3s;
  transform: translateX(0px);
  .newEditor {
    margin: auto;
  }
}

.transformComponent {
  overflow: visible !important;
}
