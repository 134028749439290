$action-buttons-height: 31px;

.iconButtonContainer {
  height: $action-buttons-height;
  display: flex;
  line-height: $action-buttons-height;
  position: relative;
  cursor: pointer;

  &:hover {
    color: var(--accent-light-color);
    svg {
      fill: var(--accent-light-color);
      stroke: var(--accent-light-color);
    }
  }

  > * {
    margin: 0;
    margin-right: 4px;
  }
  p {
    margin-left: 4px;
  }
  :last-child {
    margin-right: 0px;
  }
  :not(:first-child) {
    margin-left: 4px;
  }
  // .icon-button {
  //   margin-top: calc((#{$action-buttons-height} - 19px) / 2);
  //   margin-left: auto;
  //   margin-right: auto;
  //   stroke: #343434;
  //   stroke-width: 5;
  //   width: 19px;
  //   height: 19px;
  // }
}
