.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    margin-right: 5px;
    font-size: 14px;
    font-weight: normal;
    margin-left: 7px;
  }
  .react-toggle--checked {
    &:hover {
      .react-toggle-track {
        background-color: var(--accent-light-color-darken);
      }
      .react-toggle-thumb {
        border-color: var(--accent-light-color-darken);
      }
    }
    .react-toggle-track {
      background-color: var(--accent-light-color);
    }
    .react-toggle-thumb {
      border-color: var(--accent-light-color);
    }
  }
}
