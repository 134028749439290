.container {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.input {
  input,
  select {
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 1em;
    padding-right: 1em;
    background-color: rgb(255, 255, 255);
    border-color: transparent;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    appearance: none;
    box-shadow: 0 0 13px #f1f1f1;
    
    &:focus-visible {
      outline: 0px solid grey;
    }
  }
  select {
    background-repeat: no-repeat;
    background-size: 18px auto;
    background-position: right 12px center;
    background-image: url("data:image/svg+xml;charset=utf-8, \
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'> \
        <path fill='%23A8ACB8' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path> \
        </svg>");
  }
  margin-bottom: 10px;
}
.label {
  font-size: 0.9rem;
  // font-weight: bold;
}
