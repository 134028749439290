.option {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  transition-duration: 0.3s;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  cursor: pointer;
  &:hover, &:active {
    border-color: var(--accent-light-color);
    img {
      transform: scale(1);
      opacity: 1;
    }
  }
  img {
    transform: scale(0.97);
    opacity: 0.75;
    transition-duration: 0.3s;
    object-fit: contain;
    width: 100%;
  }
}
