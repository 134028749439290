.editorPageContainer {
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  :global(._loading_overlay_wrapper) :global(._loading_overlay_overlay) {
    background: #f8f8f869;
  }
}
