.button {
  background-color: var(--accent-light-color);
  border: none;
  color: white;
  padding: 8px 20px 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  // font-weight: 600;
  font-weight: normal;
  margin: 4px 2px;
  outline: none;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background-color: var(--accent-light-color);
  }
  &:active {
    background-color: var(--accent-light-color-darken);
  }
}
.button.rounded {
  border-radius: 15px;
}
.button.outline {
  background-color: transparent;
  color: black;
  border: 1px solid #d6d6d6;
}
.button.lg-size {
  padding: 11px 40px;
  font-size: 14px;
}
.button.xl-size {
  padding: 14px 76px;
  font-size: 22px;
}
.button.md-text {
  font-size: 15px;
}
.button.round.xl-size {
  border-radius: 11px;
}
.button.azure-color {
  background-color: var(--accent-light-color);
  border-color: var(--accent-light-color);
  &:hover,
  &:active {
    background-color: var(--accent-light-color-darken);
    border-color: var(--accent-light-color-darken);
  }
  &.outline {
    background-color: transparent;
    color: var(--accent-light-color-darken);
  }
  &:disabled,
  &.disabled {
    background-color: var(--accent-light-color-disabled) !important;
    color: #ececec !important;
    cursor: default;
  }
}
.button.secondary-color {
  background-color: white;
  color: var(--accent-light-color-darken);
  border-color: var(--accent-light-color-darken);
  &:hover {
    background-color: var(--accent-light-color-darken);
    color: white;
    border-color: var(--accent-light-color-darken);
  }
}

.button.black-color {
  &.outline {
    background-color: transparent;
    color: var(--black-color);
    border-color: var(--black-color);
  }
  background-color: var(--black-color);
  color: white;
  &:hover {
    background-color: var(--accent-light-color);
    color: white;
    // border-color: var(--accent-light-color);
  }
  &:active {
    background-color: var(--accent-light-color-darken);
    color: white;
    border-color: var(--accent-light-color-darken);
  }
}
