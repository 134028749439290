.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #ffcc00;
  margin-bottom: 30px;
  * {
    margin-left: 10px;
  }
  :first-child {
    margin-left: 0px;
  }
}

.danger {
  color: #ff5959;
}
