$toolbar-border-radius: 15px;
/* Side bar */
.toolbar {
  max-height: 100%;
  width: var(--toolbar-width);
  user-select: none;
  flex: 0 0 var(--toolbar-width);
  ul {
    margin: 0;
    height: 100%;
    padding: 20px 0;
    position: relative;
    z-index: 20;
    button.active,
    button:hover {
      background-color: white;
      color: var(--navbar-active-color);
      font-weight: bold;
    }
    li {
      padding: 0;
      list-style: none;
      padding-left: 5px;
      margin-bottom: 10px;
      max-width: 100%;
    }
    button {
      cursor: pointer;
      color: #555;
      background-color: transparent;
      text-decoration: none;
      padding: 15px 20px 15px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      letter-spacing: 0px;
      font-size: 12px;
      border-top-left-radius: $toolbar-border-radius;
      border-bottom-left-radius: $toolbar-border-radius;
      margin: 0;
      border: none;
      width: 100%;
      transition: 0.25s;
    }
    button:focus {
      outline: none;
    }
    button:hover:not(.active) {
      background-color: rgba(255, 255, 255, 0.95);
      font-weight: bold;
    }
  }
  .toolbar-view.hide {
    width: 0;
    padding: 0;
  }
  .toolbar-view {
    position: absolute;
    left: var(--toolbar-width);
    top: 0;
    width: var(--toolbar-view-width);
    z-index: var(--z-toolbar);
    background-color: white;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    max-height: calc(var(--vh, 1vh) * 100 - var(--navbar-height));
    border-radius: $toolbar-border-radius $toolbar-border-radius 0px 0px;
  }
  .toolbar-icon {
    margin-bottom: 5px;
    font-size: 25px;
  }
}
.toolbar-inner {
  height: 100%;
  width: 100%;
  position: fixed;
  width: var(--toolbar-width);
  z-index: var(--z-toolbar);
  top: var(--navbar-height);
}
.mobile {
  .toolbar {
    ul {
      li {
        margin-bottom: 0px;
      }
      button {
        padding: 10px 10px 10px 10px;
      }
    }
  }
}

/* Side bar end */
.toolbar-view-component-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 10px;
}

.disable-toolbar {
  position: absolute;
  cursor: pointer;
  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00000053;
}

.toggle-hide-button {
  position: absolute;
  cursor: pointer;
  display: flex;
  box-shadow: 4px 0 5px 0px rgba(0, 0, 0, 0.1);
  left: 100%;
  top: 50%;
  margin-top: -50px;
  height: 100px;
  width: 15px;
  background: white;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}