.mobile {
  .toolbar-text > div {
    margin: auto 5px !important;
  }
  .break {
    flex-basis: 100% !important;
  }
  .toolbar-text {
    height: calc(2 * var(--footer-height)) !important;
    display: flex !important;
    flex-wrap: wrap !important;
    .text-color-button {
      min-width: 20px;
    }
    .font-size-button {
      min-width: 60px;
    }
  }
  // .color-picker-container{
  //   margin-left: 0%;
  // }
}

.editTextNavbar {
  z-index: var(--z-action-bar);
  position: fixed;
  display: flex;
  width: 100%;
  padding: 10px;
}

.toolbar-text {
  overflow: inherit !important;
  font-size: 90%;
  svg {
    margin-right: 0px !important;
  }
  svg.left-action {
    margin-right: 5px !important;
  }

  .text-button {
    min-width: 150px;
  }

  .font-size-button {
    min-width: 70px;
  }

  .delete-button {
    min-width: 60px;
    margin-right: auto !important;
  }
}

// .color-picker-container{
//   margin-left: 60%;
// }

.text-align-buttons {
  min-width: 90px;
  // color: black;
  .multi-option-button-container {
    border: unset;
    padding: 0px;
    > * {
      color: black;
      border: unset;
      border-radius: 6px;
    }
    .selected {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }
    .multi-option-button {
      height: 33px;
    }
  }
}

.break {
  width: 0 !important;
  margin: 0 !important;
}
