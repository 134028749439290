.progress {
  position: relative;
  margin: 5px;
  width: 100%;
  background: #e3e6e8;
  border-radius: 4px;
  height: 7px;
  overflow: hidden;
  > span {
    display: block;
    height: 100%;
    border-radius: 4px;
    background-color: #383838;
    position: relative;
    overflow: hidden;
  }
}

.progressBarContainer {
  position: absolute;
  width: 100%;
  // padding: 5px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  height: 25px;
}
