.main-layout {
  min-height: 100%;
  height: 100%;
  background: var(--bg);
  .content-wrapper {
    // padding-top: var(--navbar-height);
    display: flex;
    min-height: 100%;
    height: 100%;
    overflow: auto;
    .content {
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      width: 100%;
      padding-top: var(--navbar-height);
    }
    .width-without-toolbar {
      max-width: calc(100vw - var(--toolbar-width));
    }
  }
  .navbar {
    position: fixed;
    width: 100%;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    padding: 0 var(--navbar-padding-horizontal);
    padding-right: 10px;
    // pointer-events: visible;
    z-index: var(--z-navbar);
    .navbar-right {
      display: flex;
      margin-left: auto;
      text-align: right;
    }
    .design-id {
      color: #a0a0a0;
      margin-top: auto;
      margin-bottom: auto;
      padding-top: 7px;
      margin-right: 30px;
      font-size: 0.85em;
    }
    .download-button {
      background-color: Transparent;
      margin-right: 10px;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .logo-link {
      display: flex;
    }
    .logo {
      height: 50px;
      margin-left: 7px;
    }
  }
}
.main-layout.mobile {
  overflow: hidden;
  .content-wrapper {
    > * {
      padding-bottom: var(--footer-height);
    }
    .content {
      max-width: 100vw;
      position: relative;
      width: 100%;
      overflow: hidden;
    }
    .all-page {
      padding-bottom: 0px;
    }
  }
  .navbar {
    padding: 0px var(--navbar-padding-horizontal);
    .navbar-right {
      .design-id {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .logo {
      width: 30px;
      margin-left: 0;
    }
  }
}

button {
  &:disabled {
    color: #949494 !important;
    background-color: rgb(113, 105, 162) !important;
    border-color: rgb(113, 105, 162) !important;
  }
}
.mobile {
  .hide-on-mobile {
    display: none;
  }
}

/* text admin */
.navbar-admin {
  height: 34px;
  margin-left: 10px;
  font-size: 22px;
  color: white;
}

// @media (max-width: 390px) {
// 	.main-layout.mobile {
// 		.navbar {
// 			.navbar-right {
// 				>button {
// 					font-size: 14px;
// 					padding-right: 10px;
// 					padding-left: 10px;
// 				}
// 			}
// 		}
// 	}
// }
// @media (max-width: 500px) {
// 	.main-layout {
// 		.navbar {
// 			.order-button {
// 				>svg {
// 					margin-right: 2px !important;
// 				}
// 				>div {
// 					display: none;
// 				}
// 			}
// 		}
// 	}
// }
