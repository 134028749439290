.divFullPage {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: var(--z-color-picker);
  :global(.mobile) & {
    top: 0px;
    bottom: initial;
  }
}
