.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModalPortal > div {
  background-color: rgba(0, 0, 0, 0.65) !important;
}
.ReactModalPortal > div > div {
  max-width: 395px;
}

.modal .loading-container svg {
  width: 90px;
  height: 90px;
  margin-top: 30px;
  margin-bottom: 15px;
}

@media (max-width: 325px) {
  .modal.order .buttons button {
    margin-right: 15px;
    margin-left: 15px;
    font-size: 16px;
  }
}

.modal-warning {
  border: 1px solid #af2a2a !important;
  background: #fdd6d6;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
}

.react-modal {
  text-align: center;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  border: unset;
  border-radius: 10px;
  width: 85vw;
  transform: translate(-50%, -50%);
  position: relative;
  background: white;
  padding: 0px;
  outline: none;
  &:focus-visible {
    outline: none;
  }
  .modal-header {
    text-align: center;
    font-weight: normal;
    text-align: center;
    margin-top: 0px;
    width: 100%;
    font-size: 26px;
    padding: 20px;
    .modal-header-icon {
      margin-left: auto;
      margin-right: 12px;
    }
    .modal-header-text {
      margin-right: auto;
      margin-top: auto;
      // margin-bottom: 16px;
      font-size: 0.9em;
    }
    .subtitle {
      font-size: 12px;
      line-height: 18px;
      color: #a8acb8;
      b {
        color: black;
        font-weight: normal;
      }
    }
  }
  .modal-body {
    padding: 20px;
    :first-child {
      margin-top: 0px;
    }
    :last-child {
      margin-bottom: 0px;
    }
    .modal-text {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
      opacity: 0.8;
      font-size: 17px;
    }
  }
  .modal-header + .modal-body {
    padding-top: 0;
  }
  .modal-body + .action-buttons,
  .modal-body + .action-buttons-vertical {
    padding-top: 0;
  }

  .action-buttons {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
  }
  .action-buttons-vertical {
    padding: 20px;
    text-align: center;
    margin: unset;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .number-input {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    width: 100%;
    padding: 2px 10px;
  }
}

.modal-cancel-button {
  z-index: 10;
  font-size: 22px;
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 5px;
  border: none !important;
}

.react-modal {
  &.configuration-calendar {
    max-width: 335px;
  }
  &.configuration_photoBook {
    max-width: 100% !important;
  }
}

// .mobile {
//   &.react-modal {
//     padding: 20px;
//   }
// }
