.container {
  // width: 100%;
  // height: 100%;
  // background-color: red;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  width: 100%;
  // height: 110px;

  button:global(.button) {
    // padding-left: 8px;
    // padding-right: 8px;
    // width: 48% !important;
    width: 100%;
    // border: 1px solid;
    min-height: 75px;
    padding: 8px;
    text-align: center;
    :global(.mobile) & {
      font-size: 12px;
      padding: 4px;
      min-height: 75px;
    }
  }

  :global(.grid) {
    width: 100%;
    
    :global(.grid-item) {
      padding-top: 0 !important;
      height: auto !important;
      :global(.grid-item-inner) {
        position: static;
      }
    }

    :global(.mobile) & {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      :global(.grid-item) { 
        flex: 0 0 33%;
        // width: auto !important;
      }
    }
  }
}


// :global(.mobile) {
//   .container {
//     flex-direction: row;
//   }
// }