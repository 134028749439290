.container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.svg {
  overflow: visible;
  margin: auto;
}

.zoomContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
}
