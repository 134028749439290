.done-button {
  height: 31px;
  line-height: 31px;
  background: #343434;
  border-radius: 10px;
  padding: 0px 20px;
  font-size: 16px;
  margin: 0px;
  margin-left: auto;
}
