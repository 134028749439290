.select {
  position: relative;
  .selectIcon {
    position: absolute;
    top: 12px;
    left: 12px;
    svg {
      color: var(--accent-light-color);
      width: 14px;
      height: 14px;
    }
    & + select {
      padding-left: 2em;
    }
  }
}