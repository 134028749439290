.crop {
  border-radius: 0px 10px 0px 0px;
  padding: 6px 8px;
  background-color: #80808094;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: white;
  cursor: pointer;
  &:hover {
    color: var(--accent-light-color);
  }
}
