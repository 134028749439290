.layoutsContainer {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  :global(.grid-back-container) {
    height: 40%;
  }
  .layoutOptionContainer {
    stroke: var(--black-color);
    &:hover {
      stroke: var(--accent-light-color);
    }
    &:active {
      stroke: var(--accent-light-color-darken);
    }
    > svg {
      display: block;
    }
    padding: 15px;
    width: 100%;
    height: 100%;
  }
  .switch {
    margin: 10px;
    justify-content: flex-start;
  }
}

:global(.no-layout-switch) :global(.grid-back-container) {
  height: 100%;
}
